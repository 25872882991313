// import { useSetUserData } from "../hooks/use-set-user-data"

import { useDispatch } from "react-redux"
import { useSetUserData } from "../hooks/use-set-user-data"
import { useUserQuery } from "../queries/use-user-query"
import { useEffect } from "react"
import { setUser } from "../store"
import { Loading } from "../../../components/loading"

/**
 * @param {import('react').PropsWithChildren} props
 * @returns {import('react').ReactNode}
 */
// eslint-disable-next-line react/prop-types
export const AuthenticationInitializer = ({ children }) => {
  const setUserData = useSetUserData()

  const dispatch = useDispatch()

  const { data, isPending, isSuccess } = useUserQuery()

  // if (isSuccess && data) {
  //   console.log(data)

  //   dispatch(setUser(data.data))
  // }
  useEffect(() => {
    if (isSuccess && data) {
      console.log("init auth", data)
      dispatch(setUser(data))
      setUserData(data)
    }
  }, [isSuccess, data, dispatch])

  if (isPending) {
    return <Loading />
  }

  return <>{children}</>
}
