/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import "../../src/assets/js/app";
// import { baricon1, imguser, logo, noteicon, noteicon1, searchnormal, settingicon01, user06 } from './imagepath';
import {
  loginlogo,
  baricon,
  baricon1,
  searchnormal,
  noteicon,
  user06,
  settingicon01,
  noteicon1,
} from "./imagepath";
import { useUserFromStore } from "../features/auth/hooks/use-user-from-store";

const Header = () => {
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
    document.getElementsByTagName("html")[0].classList.toggle("menu-opened");
    document
      .getElementsByClassName("sidebar-overlay")[0]
      .classList.toggle("opened");
  };

  const openDrawer = () => {
    const div = document.querySelector(".main-wrapper");
    if (div?.className?.includes("open-msg-box")) {
      div?.classList?.remove("open-msg-box");
    } else {
      div?.classList?.add("open-msg-box");
    }
  };

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    // maximizeBtn.addEventListener('click', handleClick);

    return () => {
      // maximizeBtn.removeEventListener('click', handleClick);
    };
  }, []);
  const user = useUserFromStore()
console.log("user" , user)
  return (
    <div className="main-wrapper">
      <div className="header">
        <div className="header-left">
          <Link to="/admin-dashboard" className="logo">
            <img src={loginlogo} width="150" alt="" />{" "}
          </Link>
        </div>
        <Link
          id="mobile_btn"
          className="mobile_btn float-start"
          to="#"
          onClick={handlesidebarmobilemenu}
        >
          <img src={baricon1} alt="" />
        </Link>
        {/* <div className="top-nav-search mob-view">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
            />
            <Link className="btn">
              <img src={searchnormal} alt="" />
            </Link>
          </form>
        </div> */}
          <ul className="nav user-menu float-end h-100">
          <li className="nav-item dropdown has-arrow user-profile-list">
            <div
              className="dropdown-toggle nav-link user-link h-100"
            >
              <div className="user-names">
                <h5>{user?.username} </h5>
                <span>{user?.email}</span>
              </div>
              {/* <span className="user-img">
                <img src={user06} alt="Admin" />
              </span> */}
            </div>
          </li>
         
        </ul>
      </div>
    </div>
  );
};

export default Header;
