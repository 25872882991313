import React, { useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import DonutChart from "./DonutChart";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import PatientChart from "./PaitentChart";
import Select from "react-select";
import { imagesend, user001 } from "../../imagepath";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import PageHeader from "../../common/PageHeader";
import { FaChalkboardTeacher } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { IoBookmarksOutline } from "react-icons/io5";
import { CiMoneyBill } from "react-icons/ci";

const Admin_Dashboard = () => {
  return (
    <>
      <Header />
      <Sidebar
        activeClassName="user-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <PageHeader title="Tableau de bord" />
            <HomeOverview />
            <HomeBarAndPieChart />
            <HomeLatestSinUp />
          </div>

          {/* delete modal */}
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="delete_patient"
              className="modal fade delete-modal"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <img src={imagesend} alt="#" width={50} height={46} />
                    <h3>Are you sure want to delete this ?</h3>
                    <div className="m-t-20">
                      {" "}
                      <Link
                        to="#"
                        className="btn btn-white me-2"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </Link>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

const HomeOverview = () => {
  return (
    <div className="row">
      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
        <div className="dash-widget">
          <div className="dash-boxs comman-flex-center">
            <FaChalkboardTeacher className="home-icon" />
          </div>
          <div className="dash-content dash-count flex-grow-1">
            <h4>Professeurs</h4>
            <h2>
              {" "}
              <CountUp delay={0.4} end={250} duration={0.6} />
            </h2>
            <p>
              <span className="passive-view">
                <i className="feather-arrow-up-right me-1">
                  <FeatherIcon icon="arrow-up-right" />
                </i>
                40%
              </span>{" "}
              vs le mois dernier
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
        <div className="dash-widget">
          <div className="dash-boxs comman-flex-center">
            <PiStudentFill className="home-icon" />
          </div>
          <div className="dash-content dash-count">
            <h4>Étudiants</h4>
            <h2>
              <CountUp delay={0.4} end={140} duration={0.6} />
            </h2>
            <p>
              <span className="passive-view">
                <i className="feather-arrow-up-right me-1">
                  <FeatherIcon icon="arrow-up-right" />
                </i>
                20%
              </span>{" "}
              vs le mois dernier
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
        <div className="dash-widget">
          <div className="dash-boxs comman-flex-center">
            <IoBookmarksOutline className="home-icon" />
          </div>
          <div className="dash-content dash-count">
            <h4>Announcements</h4>
            <h2>
              <CountUp delay={0.4} end={456} duration={0.6} />
            </h2>
            <p>
              <span className="negative-view">
                <i className="feather-arrow-down-right me-1">
                  <FeatherIcon icon="arrow-down-right" />
                </i>
                15%
              </span>{" "}
              vs last month
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
        <div className="dash-widget">
          <div className="dash-boxs comman-flex-center">
            <CiMoneyBill className="home-icon" />
          </div>
          <div className="dash-content dash-count">
            <h4>Revenue</h4>
            <h2>
              <CountUp delay={0.4} end={20250} duration={0.6} /> MAD
            </h2>
            <p>
              <span className="passive-view">
                <i className="feather-arrow-up-right me-1">
                  <FeatherIcon icon="arrow-up-right" />
                </i>
                30%
              </span>{" "}
              vs le mois dernier
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeBarAndPieChart = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [year, setyear] = useState([
    { value: 1, label: "2022" },
    { value: 2, label: "2021" },
    { value: 3, label: "2020" },
    { value: 4, label: "2019" },
  ]);

  return (
    <div className="row">
      {/* bar */}
      <div className="col-12 col-md-12 col-lg-6 col-xl-9">
        <div className="card">
          <div className="card-body">
            <div className="chart-title patient-visit">
              <h4>
                Tuteurs par sexe
              </h4>
              <div>
                <ul className="nav chat-user-total">
                  <li>
                    <i
                      className="fa fa-circle current-users"
                      aria-hidden="true"
                    />
                    Male 75%
                  </li>
                  <li>
                    <i className="fa fa-circle old-users" aria-hidden="true" />{" "}
                    Female 25%
                  </li>
                </ul>
              </div>
              <div className="form-group mb-0">
                <Select
                  className="custom-react-select"
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={year}
                  id="search-commodity"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused
                        ? "none"
                        : "2px solid rgba(46, 55, 164, 0.1);",
                      boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                      "&:hover": {
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1)",
                      },
                      borderRadius: "10px",
                      fontSize: "14px",
                      minHeight: "45px",
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(-180deg)"
                        : "rotate(0)",
                      transition: "250ms",
                      width: "35px",
                      height: "35px",
                    }),
                  }}
                />
              </div>
            </div>
            <div id="patient-chart" />
            <PatientChart />
          </div>
        </div>
      </div>
      {/* pie */}
      <div className="col-12 col-md-12 col-lg-6 col-xl-3 d-flex">
        <div className="card">
          <div className="card-body">
            <div className="chart-title">
              <h4>Tuteurs par matière</h4>
            </div>
            <div id="donut-chart-dash" className="chart-user-icon">
              <DonutChart />
              <img src={user001} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeLatestSinUp = () => {
  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <div className="card">
          <div className="card-header pb-0">
            <h4 className="card-title d-inline-block">Les derniers inscrits</h4>{" "}
            <Link to="#" className="float-end patient-views">
              Voir tout
            </Link>
          </div>
          <div className="card-block table-dash">
            <div className="table-responsive">
              <table className="table mb-0 border-0 datatable custom-table">
                <thead>
                  <tr>
                    <th>
                      <div className="form-check check-tables">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue="something"
                        />
                      </div>
                    </th>
                    <th>No</th>
                    <th>Nom complet</th>
                    <th>age</th>
                    <th>Date de naissance</th>
                    <th>type de compte</th>
                    <th>Statut</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="form-check check-tables">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue="something"
                        />
                      </div>
                    </td>
                    <td>R00001</td>
                    <td>
                      <h2>Andrea Lalema</h2>
                    </td>
                    <td>21</td>
                    <td>07 January 2024</td>
                    <td>Etudiant</td>
                    <td>
                      <button className="custom-badge status-green ">
                        Actif
                      </button>
                    </td>
                    <td className="text-end">
                      <div className="dropdown dropdown-action">
                        <Link
                          to="#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                          <Link className="dropdown-item" to="/editpatients">
                            <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                            Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_patient"
                          >
                            <i className="fa fa-trash-alt m-r-5"></i> Delete
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin_Dashboard;
