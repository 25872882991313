import { Link, useNavigate } from "react-router-dom"
import Header from "../Header"
import Sidebar from "../Sidebar"
import PageHeader from "../common/PageHeader"
import { useState } from "react"
import { AllApi } from "../../features/api"

const AddCategoryPage = () => {
  const [titre, setTitre] = useState("")
  const [image, setImage] = useState(null)
  const [description, setDescription] = useState("")
  // const [status, setStatus] = useState("active")
const navigate= useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append("title", titre)
    formData.append("icon", image)
    formData.append("description", description)
    // formData.append("status", status)

    try {
      const response = await AllApi.createCategorie(formData)
      navigate("/categories/all")
      console.log("Categorie created successfully:", response)
    } catch (error) {
      console.error("Error creating categorie:", error)
    }
  }
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="all-categories"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <PageHeader
              title="Catégorie"
              subTitle="Ajouter une catégorie"
              titleLink="/categories/all"
            />
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Ajouter une catégorie</h4>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Nom de la catégorie{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={titre}
                              onChange={(e) => setTitre(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-top-form">
                            <label className="local-top">
                              Image de la catégorie{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <div className="settings-btn upload-files-avator">
                              <input
                                type="file"
                                accept="image/*"
                                name="image"
                                id="file"
                                className="hide-input"
                                onChange={(e) => setImage(e.target.files[0])}
                              />
                              <label htmlFor="file" className="upload">
                                Choisir le fichier
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Description de la catégorie{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="active"
                                  checked={status === "active"}
                                  onChange={(e) => setStatus(e.target.value)}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="inactive"
                                  checked={status === "inactive"}
                                  onChange={(e) => setStatus(e.target.value)}
                                />
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Ajouter la catégorie
                            </button>
                            <Link
                              to="/categories/all"
                              className="btn btn-primary cancel-form"
                            >
                              Annuler
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default AddCategoryPage
