export const profKeys = {
  all: () => ["profs"],
  getById: (id) => [...profKeys.all(), id],
  getFiltered: (filters) => [...profKeys.all(), filters],
}


export const categoriesKeys = {
  all: () => ["categories"],
  getById: (id) => [...categoriesKeys.all(), id],
  getFiltered: (filters) => [...categoriesKeys.all(), filters],
}
