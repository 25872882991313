/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react"
import { Table } from "antd"
import { Link, useSearchParams } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { usePageCurrent } from "../doctor copy/usePageCurrent"
import { omitUndefinedAndNull } from "../../features/utils/omitUndefinedAndNull"
import { profKeys } from "../../features/doctors/query-keys"
import { itemRender } from "../Pagination"
import { refreshicon, searchnormal } from "../imagepath"
import DeletePatientModal from "../ConfirmDeleteModel"
import { DashboardLayout } from "../../features/layouts/DashboardLayout"
import { AllApi } from "../../features/api"

const DemandeList = () => {
  const [urlParams, setUrlParams] = useSearchParams()

  const { page, setPage } = usePageCurrent()

  const sortOptions = useMemo(() => {
    const sortBy = urlParams.get("sortBy") || "created_at"
    const order = urlParams.get("order") || "asc"
    const search = urlParams.get("search")

    return {
      sortBy,
      order,
    }
  }, [urlParams])

  const setSortOptions = (sortBy, order, search) => {
    setUrlParams((prev) => {
      prev.set("sortBy", sortBy)
      prev.set("order", order)
      prev.set("search", search)
      return prev
    })
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isDeleteManyModalOpen, setIsDeleteManyModalOpen] = useState(false)

  const [recordIdToDelete, setRecordIdToDelete] = useState(null)

  const [searchQuery, setSearchQuery] = useState(urlParams.get("search"))
  console.log(
    omitUndefinedAndNull({
      page,
      paginate: 5,
      sort_by: sortOptions.sortBy,
      order: sortOptions.order,
      search: urlParams.get("search"),
    })
  )

  const { data, isPending, isError, refetch, isRefetching } = useQuery({
    queryKey: profKeys.getFiltered({
      page,
      paginate: 5,
      sortBy: sortOptions.sortBy,
      order: sortOptions.order,
      search: urlParams.get("search"),
    }),
    queryFn: async () =>
      await AllApi.getAllAnnonces(
        omitUndefinedAndNull({
          page,
          paginate: 5,
          active: "false",
          // sort_by: sortOptions.sortBy,
          // order: sortOptions.order,
          search: urlParams.get("search"),
        })
      ),
  })
  
  const { mutateAsync: validateAnnonceAsync } = useMutation({
    mutationFn: async (id) => await AllApi.validateAnnonce(id),
    onSuccess: () => refetch(),
  })

  const { mutateAsync: blockAnnonceAsync } = useMutation({
    mutationFn: async (id) => await AllApi.blockAnnonce(id),
    onSuccess: () => refetch(),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (id) => await AllApi.deleteDoctor(id),
    onSuccess: () => refetch(),
  })

  //delete many doctor
  const { mutateAsync: deleteManyAsync } = useMutation({
    mutationFn: async (ids) => await AllApi.deleteDoctorMany(ids),
    onSuccess: () => {
      refetch()
      setSelectedRowKeys([])
    },
  })

  /**
   * @type {import("antd").TableProps<any>.columns} columns
   */
  const columns = [
    {
      title: "Titre",
      dataIndex: "title",
      // sorter: true,
    },
    {
      title: "Année d'expérience",
      dataIndex: "years_experience",
      // sorter: true,
    },
    {
      title: "Réserver à prof",
      dataIndex: "reservation_at_prof",
      // sorter: true,
    },
    {
      title: "Réserver à client",
      dataIndex: "reservation_at_client",
      // sorter: true,
    },
    {
      title: "Tarification",
      dataIndex: "tarification",
      // sorter: true,
    },
    {
      title: "Catégorie",
      dataIndex: "categorie.title",
      render: (text, record) => (
        <>
          <span>{record?.categorie?.title}</span>
        </>
      ),
    },
    {
      title: "Niveau scolaire",
      dataIndex: "school_level.title",
      render: (text, record) => (
        <>
          <span>{record?.school_level?.title}</span>
        </>
      ),
    },
    {
      title: "Prof",
      dataIndex: "prof.title",
      render: (text, record) => (
        <>
          <span>{record?.prof?.username}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
              <button
                  className="dropdown-item"
                  onClick={() => {
                    validateAnnonceAsync(record.id)
                  }}
                >
                  <i className="fa fa-check m-r-5"></i> Valider
                </button>

                <button
                  className="dropdown-item"
                  onClick={() => {
                    blockAnnonceAsync(record.id)
                  }}
                >
                  <i className="fa fa-ban m-r-5"></i> Bloquer 
                </button>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    const nextBtn = document.querySelector(".ant-pagination-next")

    if (nextBtn) {
      nextBtn.classList.add("me-3")
    }
  }, [])

  const [tableParams, setTableParams] = useState({
    pagination: {
      total: data?.total,
      pageSize: data?.per_page,
      current: page,
      showTotal: (total, range) =>
        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
      showSizeChanger: false,
      itemRender: itemRender,
    },
    sortOrder: sortOptions.order,
    sortField: sortOptions.sortBy,
  })

  useEffect(
    () => {
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: data?.total,
          pageSize: data?.per_page,
          current: page,
        },
      }))
    } /* eslint-disable-next-line */,
    [data]
  )

  /**
   *
   * @type {import("antd").TableProps<DataType>['onChange']} handleTableChange
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const sortBy = sorter.field || "created_at"
    const order = sorter.order || "desc"

    setTableParams({
      pagination,
      filters,
      sortOrder: order,
      sortField: sortBy,
    })

    setUrlParams((prev) => {
      prev.set("page", pagination.current)
      prev.set("sortBy", sortBy)
      prev.set("order", order === "descend" ? "asc" : "desc")
      return prev
    })
  }

  useEffect(() => {
    refetch() // Fetch the data again when URL params change
  }, [urlParams, refetch])

  if (isError) {
    return <p>Failed to load Liste des annonces.</p>
  }
  return (
    <>
      {/* Table Header */}
      <div className="page-table-header mb-2">
        <div className="row align-items-center">
          <div className="col">
            <div className="doctor-table-blk">
              <h3>Liste des annonces</h3>
              <div className="doctor-search-blk">
                <div className="top-nav-search table-search-blk">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      // Update the URL params when the form is submitted
                      setUrlParams((prev) => ({
                        ...prev,
                        search: searchQuery,
                        page: 1, // Reset to page 1 when searching
                      }))
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      value={searchQuery}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setUrlParams((prev) => {
                            prev.delete("search")
                            prev.set("page", 1)
                            return prev
                          })
                        }
                        setSearchQuery(e.target.value)
                      }}
                    />
                    <button type="submit" className="btn">
                      <img src={searchnormal} alt="Search" />
                    </button>
                  </form>
                </div>
                <div className="add-group">
                  {/* <Link
                    to="/add-doctor"
                    className="btn btn-primary add-pluss ms-2"
                  >
                    <img src={plusicon} alt="#" />
                  </Link> */}

                  <button
                    className={"btn btn-primary doctor-refresh ms-2"}
                    disabled={isRefetching}
                    onClick={() => refetch()}
                  >
                    <img
                      className={`${isRefetching ? "rotate-animation" : ""}`}
                      src={refreshicon}
                      alt="#"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-auto text-end float-end ms-auto add-group">
            <button
              disabled={
                isPending || isRefetching || selectedRowKeys.length === 0
              }
              className={"btn btn-danger doctor-refresh ms-2"}
              onClick={() => {
                if (selectedRowKeys.length) {
                  setIsDeleteManyModalOpen(true)
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="m18 9l-.84 8.398c-.127 1.273-.19 1.909-.48 2.39a2.5 2.5 0 0 1-1.075.973C15.098 21 14.46 21 13.18 21h-2.36c-1.279 0-1.918 0-2.425-.24a2.5 2.5 0 0 1-1.076-.973c-.288-.48-.352-1.116-.48-2.389L6 9m7.5 6.5v-5m-3 5v-5m-6-4h4.615m0 0l.386-2.672c.112-.486.516-.828.98-.828h3.038c.464 0 .867.342.98.828l.386 2.672m-5.77 0h5.77m0 0H19.5"
                ></path>
              </svg>
            </button>
          </div> */}
        </div>
      </div>
      {/* /Table Header */}
      <div className="table-responsive doctor-list">
        <Table
          columns={columns}
          dataSource={data?.data}
          loading={isPending}
          pagination={tableParams.pagination}
          // pagination={{
          //   total: data?.total,
          //   pageSize: data?.per_page,
          //   current: page,
          //   showTotal: (total, range) =>
          //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          //   showSizeChanger: false,
          //   itemRender: itemRender,
          //   onChange: setPage,
          // }}
          onChange={handleTableChange}
          // rowSelection={{
          //   selectedRowKeys,
          //   onSelect: (_, __, selectedRows) => {
          //     setSelectedRowKeys(selectedRows.map((row) => row.id))
          //   },
          // }}
          rowKey={({ id }) => id}
          style={{
            backgroundColor: "#f2f2f2", // Replace with your desired background color for the table
          }}
        />
      </div>

      {/* Delete Many Model */}
      <DeletePatientModal
        message={"Are you sure you want to delete this annonces?"}
        isModalOpen={isDeleteManyModalOpen}
        onOkClick={async () => {
          await deleteManyAsync(selectedRowKeys)
          setIsDeleteManyModalOpen(false)
        }}
        onCancelClick={() => {
          setIsDeleteManyModalOpen(false)
        }}
      />
      {/* /Delete Model */}

      {/* Delete Model */}
      <DeletePatientModal
        message={"Are you sure you want to delete this annonces?"}
        isModalOpen={isModalOpen}
        onOkClick={async () => {
          await mutateAsync(recordIdToDelete)
          setRecordIdToDelete(null)
          setIsModalOpen(false)
        }}
        onCancelClick={() => {
          setRecordIdToDelete(null)
          setIsModalOpen(false)
        }}
      />
      {/* /Delete Model */}
    </>
  )
}

const AllDemmandesPage = () => {
  return (
    <DashboardLayout>
      <div className="content">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">annonces </Link>
                </li>
                <li className="breadcrumb-item">
                  <i className="feather-chevron-right">
                    {/* <FeatherIcon icon="chevron-right" /> */}
                  </i>
                </li>
                <li className="breadcrumb-item active">Liste des annonces</li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table show-entire">
              <div className="card-body">
                <DemandeList />

                <div className="notification-box">
                  <div className="msg-sidebar notifications msg-noti">
                    <div className="topnav-dropdown-header">
                      <span>Messages</span>
                    </div>
                    <div className="drop-scroll msg-list-scroll" id="msg_list">
                      <ul className="list-box">
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">R</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Richard Miles{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item new-message">
                              <div className="list-left">
                                <span className="avatar">J</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">John Doe</span>
                                <span className="message-time">1 Aug</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">T</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Tarah Shropshire{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">M</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Mike Litorus
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">C</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Catherine Manseau{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">D</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Domenic Houston{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">B</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Buster Wigton{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">R</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Rolland Webber{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">C</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Claire Mapes{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">M</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Melita Faucher
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">J</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Jeffery Lalor
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">L</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Loren Gatlin
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">T</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Tarah Shropshire
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="topnav-dropdown-footer">
                      <Link to="#">See all messages</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default AllDemmandesPage
