import React from "react"
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from "./components/pages/login"
import Error from "./components/pages/login/Error"
import Admin_Dashboard from "./components/Dashboard/Admin_Dashboard/Admin_Dashboard"
// import TutorsListPage from "./components/Users_lists/TutorsListPage"
import StudentsListPage from "./components/Users_lists/StudentsListPage"
import AllAnnouncementPage from "./components/annonce/AllAnnouncementPage"
import AllDemmandesPage from "./components/demandes/AllDemmandesPage"
import AllCategoriesPage from "./components/categories/AllCategoriesPage"
import AddCategoryPage from "./components/categories/AddCategoryPage"
import AllPaymentPage from "./components/payment/AllPaymentPage"
import AllReservationsPage from "./components/reservations/AllReservationsPage"
// import AllMessagesPage from "./components/messages/MessagesPage"
import AllFacturesPage from "./components/facture/AllFacturesPage"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { store } from "./store"
import { AuthenticationInitializer } from "./features/auth/components/init-auth"
import { Guest } from "./features/auth/components/guest"
import { Auth } from "./features/auth/components/auth"
import ProfsListPage from "./components/pages/DoctorList"
import EditCategories from "./components/categories/EditCategories"
import AllContactssPage from "./components/messages/AllContactssPage"
import AllFacturesNotPayedPage from "./components/facture/AllFacturesNotPayedPage"
import AllFacturesCanceledPage from "./components/facture/AllFacturesCanceledPage"

const Approuter = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AuthenticationInitializer>
            <BrowserRouter basename="/">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Guest>
                      <Login />
                    </Guest>
                  }
                />
                <Route
                  path="/admin-dashboard"
                  element={
                    <Auth>
                      <Admin_Dashboard />
                    </Auth>
                  }
                />
                <Route path="/users">
                  <Route path="tutors" element={<ProfsListPage />} />
                  <Route path="students" element={<StudentsListPage />} />
                </Route>
                <Route path="/announcement">
                  <Route path="all" element={<AllAnnouncementPage />} />
                </Route>
                <Route path="/demandes">
                  <Route path="all" element={<AllDemmandesPage />} />
                </Route>
                <Route path="/categories">
                  <Route path="all" element={<AllCategoriesPage />} />
                  <Route path="add" element={<AddCategoryPage />} />
                  <Route path="edit/:id" element={<EditCategories />} />
                </Route>
                <Route path="/payment">
                  <Route path="all" element={<AllPaymentPage />} />
                </Route>
                <Route path="/reservation">
                  <Route path="all" element={<AllReservationsPage />} />
                </Route>
                <Route path="/contact">
                  <Route path="all" element={<AllContactssPage />} />
                </Route>
                <Route path="/facture">
                  <Route path="all" element={<AllFacturesPage />} />
                  <Route path="encours" element={<AllFacturesNotPayedPage />} />
                  <Route path="annulés" element={<AllFacturesCanceledPage />} />
                </Route>
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<Navigate to="/error" />} />
              </Routes>
            </BrowserRouter>
            <div className="sidebar-overlay"></div>
          </AuthenticationInitializer>
        </Provider>
      </QueryClientProvider>
    </>
  )
}

export default Approuter
