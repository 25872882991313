import { apiClient } from "../../api-client"

export class ProfApi {
  static async getProfList(params = {}) {
    const { data } = await apiClient.get("users", {params})

    return data.Users
  }

  static async getProfById(id) {
    const { data } = await apiClient.get(`users/${id}`)

    return data.data
  }

  static async createProf(data) {
    const response = await apiClient.post("users", data)

    return response.data
  }

  static async updateProf(id, data) {
    const response = await apiClient.put(`users/${id}`, data)

    return response.data
  }

  static async deleteProf(id) {
    const response = await apiClient.delete(`users/${id}`)

    return response.data
  }
  static async deleteProfMany(ids) {
    const response = await apiClient.delete("users", {
      data: { ids },
    })

    return response.data
  }
}
