import axios from "axios"

// const API_URL = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: `https://app.profyy.com/api`,
  headers: {
    Accept: "application/json",
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { apiClient }
