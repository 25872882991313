import React from "react";
import { dangericon, error1 } from "../../imagepath";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <div className="main-wrapper error-wrapper">
        <div className="error-box">
          <img className="img-fluid" src={error1} alt="Logo" />
          <h3>
            <img className="img-fluid mb-0" src={dangericon} alt="Logo" />{" "}
            Erreur 404
          </h3>
          <p>
            La page que vous recherchez n&apos;a pas été trouvée ou n&apos;existe pas.
          </p>
          <Link to="/admin-dashboard" className="btn btn-primary go-home">
            Retour à la page d&apos;accueil
          </Link>
        </div>
      </div>
    </>
  );
};

export default Error;
