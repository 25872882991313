import React from "react";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";

const PageHeader = ({ title, titleLink = '#', subTitle }) => {

  return (
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={titleLink} className="text-black-54">{title}</Link>
            </li>
            {subTitle && (
              <>
                <li className="breadcrumb-item">
                  <i className="feather-chevron-right">
                    <FeatherIcon icon="chevron-right" />
                  </i>
                </li>
                <li className="breadcrumb-item text-primary">{subTitle}</li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

export default PageHeader;
