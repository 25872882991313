import { apiClient } from "../api-client"

export class AllApi {
  static async getAllAnnonces(params) {
    const { data } = await apiClient.get("annonces", { params })

    return data.Annonce
  }

// valide annonce
  static async validateAnnonce(annonce_id) {
    const response = await apiClient.post(`annonces/valideAnnonce`,{annonce_id})

    return response.data
  }
// block annonce
  static async blockAnnonce(annonce_id) {
    const response = await apiClient.post(`annonces/blockAnnonce`,{annonce_id})

    return response.data
  }

  static async getAllById(id) {
    const { data } = await apiClient.get(`users/${id}`)

    return data.data
  }

  static async createAll(data) {
    const response = await apiClient.post("users", data)

    return response.data
  }

  static async updateAll(id, data) {
    const response = await apiClient.put(`users/${id}`, data)

    return response.data
  }

  static async deleteAll(id) {
    const response = await apiClient.delete(`users/${id}`)

    return response.data
  }
  static async deleteAllMany(ids) {
    const response = await apiClient.delete("users", {
      data: { ids },
    })

    return response.data
  }
  // get all reservation
  static async getAllReservation(params) {
    const { data } = await apiClient.get("reservations", { params })

    return data.Reservation
  }
  // get all categories
  static async getAllCategories(params) {
    const { data } = await apiClient.get("categories", { params })

    return data
  }
  //store categorie
  static async createCategorie(data) {
    const response = await apiClient.post("categories", data)

    return response.data
  }
  //get categorie by id
  static async getCategorieById(id) {
    const { data } = await apiClient.get(`categories/${id}`)

    return data.Categorie
  }
  //update categorie by id
  static async updateCategorieById(id, data) {
    const response = await apiClient.post(`categories/${id}`, data)

    return response.data
  }
  //get all contacts
  static async getAllContacts(params) {
    const { data } = await apiClient.get("contact", { params })

    return data.Contact
  }
  //get all factures
  static async getAllFactures(params) {
    const { data } = await apiClient.get("facturations", { params })

    return data.Facturation
  }
  // get date facture with methode post 
  static async getDateFacture(data) {
    const response = await apiClient.post("facturations/getDates", data)

    return response.data
  }
}
